// vendors
import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import GatsbyImage from "gatsby-image"

import colors from "../../styles/variables/colors"
import { transition } from "../../styles/variables"
import { graphql } from "gatsby"

const Title = styled.span`
  grid-row: 2 / span 1;
  display: inline-block;
  justify-self: center;

  :after {
    display: block;
    height: 4px;
    content: "";
    background-color: currentColor;
    transform: scaleX(0);
    transform-origin: left;
    transition: all ${transition.speed.fast} ${transition.curve.default};
  }
`

const Vector = styled.img`
  max-height: 166px;
  max-width: 210px;
  width: 100%;
`

const Image = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`

const ImageWrapper = styled.div`
  grid-row: 1 / span 1;
  align-self: stretch;
  padding-bottom: 100%;
  position: relative;

  > div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
`

const Wrapper = styled.label`
  display: grid;
  grid-row: span 2;
  grid-template-rows: auto 1fr;
  grid-template-rows: subgrid;
  text-align: center;
  align-items: end;
  grid-row-gap: 60px;
  font-size: ${28 / 18}em;
  line-height: ${33 / 28}em;
  cursor: pointer;
  transition: all ${transition.speed.fast} ${transition.curve.default};

  :hover,
  :focus {
    color: ${colors.primary};

    ${Title} {
      :after {
        transform: scaleX(1);
        background-color: ${colors.primary};
      }
    }
  }
`

const RadioWithIcon = ({ name, options, onChange, isVector }) => {
  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: repeat(
          auto-fill,
          minmax(260px, ${isVector ? "260px" : "326px"})
        );
        grid-auto-rows: auto 1fr;
        grid-row-gap: ${isVector ? 0 : 60 / 18}rem;
      `}
    >
      {options.map((option) => {
        const labetId = `radio-${name}-${option.id}`

        return (
          <Wrapper htmlFor={labetId}>
            <input
              type="radio"
              name={name}
              value={option.id}
              id={labetId}
              onChange={onChange}
              css={css`
                display: none;
              `}
            />
            <ImageWrapper>
              <div>
                {isVector && <Vector src={option.img.src} />}

                {!isVector && <Image fluid={option.img} />}
              </div>
            </ImageWrapper>

            <Title>{option.label}</Title>
          </Wrapper>
        )
      })}
    </div>
  )
}

export default RadioWithIcon

export const query = graphql`
  fragment RadioWithIconPictureFragment on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`
