// vendors
import React from "react"
import { css } from "@emotion/core"

const StepContainer = ({ visible, children }) => {
  return (
    <div
      css={css`
        display: ${visible ? "block" : "none"};
      `}
    >
      {children}
    </div>
  )
}

export default StepContainer
