// vendors
import React from "react"
import { useFormikContext } from "formik"

import RadioWithIcon from "../../../components/RadioWithIcon/RadioWithIcon"
import QuizQuestionLayout from "../../../components/QuizQuestionLayout"

const options = [
  {
    id: "versatile",
    label: "Polyvalent et multidisciplinaire",
    img: { src: require("./images/vector-versatile.svg") },
    weighting: {
      "bas-saint-laurent": 10,
      "saguenay-lac-saint-jean": 10,
      "mauricie-et-centre-du-quebec": 10,
      outaouais: 10,
      "abitibi-temiscamingue": 10,
      "cote-nord": 10,
      "nord-du-quebec": 10,
      "iles-de-la-madeleine": 9,
      gaspesie: 10,
      "laurentides-secteur-hautes-laurentides": 10,
      nunavik: 10,
      "terres-cries-de-la-baie-james": 10,
    },
  },
  {
    id: "benevolent",
    label: "Bienveillant et à l’écoute",
    img: { src: require("./images/vector-benevolent.svg") },
    weighting: {
      "bas-saint-laurent": 9,
      "saguenay-lac-saint-jean": 9,
      "mauricie-et-centre-du-quebec": 8,
      outaouais: 8,
      "abitibi-temiscamingue": 9,
      "cote-nord": 9,
      "nord-du-quebec": 8,
      "iles-de-la-madeleine": 10,
      gaspesie: 9,
      "laurentides-secteur-hautes-laurentides": 9,
      nunavik: 8,
      "terres-cries-de-la-baie-james": 8,
    },
  },
  {
    id: "autonomous",
    label: "Autonome et créatif",
    img: { src: require("./images/vector-autonomous.svg") },
    weighting: {
      "bas-saint-laurent": 8,
      "saguenay-lac-saint-jean": 8,
      "mauricie-et-centre-du-quebec": 9,
      outaouais: 9,
      "abitibi-temiscamingue": 8,
      "cote-nord": 8,
      "nord-du-quebec": 9,
      "iles-de-la-madeleine": 8,
      gaspesie: 8,
      "laurentides-secteur-hautes-laurentides": 8,
      nunavik: 9,
      "terres-cries-de-la-baie-james": 9,
    },
  },
]

const Question4 = ({ index, onChange }) => {
  const { setFieldValue } = useFormikContext()

  const handleChange = (e) => {
    const { value } = e.target

    const option = options.find(({ id }) => id === value)

    setFieldValue(`weighting.${index}`, option.weighting)

    onChange(e)
  }

  return (
    <QuizQuestionLayout
      title="Préférez-vous une pratique qui vous demande d’être :"
      index={index + 1}
    >
      <RadioWithIcon
        name="convenient"
        options={options}
        onChange={handleChange}
        isVector
      />
    </QuizQuestionLayout>
  )
}

export default Question4
