// vendors
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useFormikContext } from "formik"

import RadioWithIcon from "../../../components/RadioWithIcon/RadioWithIcon"
import QuizQuestionLayout from "../../../components/QuizQuestionLayout"

const query = graphql`
  query {
    sociable: file(name: { eq: "quest7-sociable" }) {
      ...RadioWithIconPictureFragment
    }
    adventurous: file(name: { eq: "quest7-aventureuse" }) {
      ...RadioWithIconPictureFragment
    }
    calm: file(name: { eq: "quest7-calme" }) {
      ...RadioWithIconPictureFragment
    }
  }
`

const Question7 = ({ index, onChange }) => {
  const data = useStaticQuery(query)
  const { setFieldValue } = useFormikContext()

  const handleChange = (e) => {
    const { value } = e.target

    const option = options.find(({ id }) => id === value)

    setFieldValue(`weighting.${index}`, option.weighting)

    onChange(e)
  }

  const options = [
    {
      id: "sociable",
      label: "Sociable",
      img: data.sociable.childImageSharp.fluid,
      weighting: {
        "bas-saint-laurent": 10,
        "saguenay-lac-saint-jean": 8,
        "mauricie-et-centre-du-quebec": 9,
        outaouais: 10,
        "abitibi-temiscamingue": 10,
        "cote-nord": 6,
        "nord-du-quebec": 7,
        "iles-de-la-madeleine": 8,
        gaspesie: 9,
        "laurentides-secteur-hautes-laurentides": 10,
        nunavik: 5,
        "terres-cries-de-la-baie-james": 9,
      },
    },
    {
      id: "adventurous",
      label: "Aventureuse",
      img: data.adventurous.childImageSharp.fluid,
      weighting: {
        "bas-saint-laurent": 8,
        "saguenay-lac-saint-jean": 8,
        "mauricie-et-centre-du-quebec": 7,
        outaouais: 6,
        "abitibi-temiscamingue": 8,
        "cote-nord": 9,
        "nord-du-quebec": 10,
        "iles-de-la-madeleine": 7,
        gaspesie: 8,
        "laurentides-secteur-hautes-laurentides": 8,
        nunavik: 10,
        "terres-cries-de-la-baie-james": 10,
      },
    },
    {
      id: "calm",
      label: "Calme",
      img: data.calm.childImageSharp.fluid,
      weighting: {
        "bas-saint-laurent": 9,
        "saguenay-lac-saint-jean": 5,
        "mauricie-et-centre-du-quebec": 8,
        outaouais: 8,
        "abitibi-temiscamingue": 6,
        "cote-nord": 7,
        "nord-du-quebec": 8,
        "iles-de-la-madeleine": 9,
        gaspesie: 10,
        "laurentides-secteur-hautes-laurentides": 7,
        nunavik: 9,
        "terres-cries-de-la-baie-james": 8,
      },
    },
  ]

  return (
    <QuizQuestionLayout
      title="Quel terme définit le mieux votre personnalité?"
      index={index + 1}
    >
      <RadioWithIcon
        name="personality"
        options={options}
        onChange={handleChange}
      />
    </QuizQuestionLayout>
  )
}

export default Question7
