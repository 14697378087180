// vendors
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useFormikContext } from "formik"

import RadioWithIcon from "../../../components/RadioWithIcon/RadioWithIcon"
import QuizQuestionLayout from "../../../components/QuizQuestionLayout"

const query = graphql`
  query {
    mountain: file(name: { eq: "quest3-montage" }) {
      ...RadioWithIconPictureFragment
    }
    CulturalEvents: file(name: { eq: "quest6-evenements-culturels" }) {
      ...RadioWithIconPictureFragment
    }
    salineWater: file(name: { eq: "quest6-eau-saline" }) {
      ...RadioWithIconPictureFragment
    }
    firstNation: file(name: { eq: "quest6-immersion-culture" }) {
      ...RadioWithIconPictureFragment
    }
  }
`

const Question6 = ({ index, onChange }) => {
  const data = useStaticQuery(query)
  const { setFieldValue } = useFormikContext()

  const handleChange = (e) => {
    const { value } = e.target

    const option = options.find(({ id }) => id === value)

    setFieldValue(`weighting.${index}`, option.weighting)

    onChange(e)
  }

  const options = [
    {
      id: "CulturalEvents",
      label: "Des événements culturels",
      img: data.CulturalEvents.childImageSharp.fluid,
      weighting: {
        "bas-saint-laurent": 8,
        "saguenay-lac-saint-jean": 8,
        "mauricie-et-centre-du-quebec": 6,
        outaouais: 10,
        "abitibi-temiscamingue": 10,
        "cote-nord": 7,
        "nord-du-quebec": 7,
        "iles-de-la-madeleine": 8,
        gaspesie: 8,
        "laurentides-secteur-hautes-laurentides": 6,
        nunavik: 4,
        "terres-cries-de-la-baie-james": 8,
      },
    },
    {
      id: "salineWater",
      label: "La proximité de l’eau saline",
      img: data.salineWater.childImageSharp.fluid,
      weighting: {
        "bas-saint-laurent": 9,
        "saguenay-lac-saint-jean": 4,
        "mauricie-et-centre-du-quebec": 0,
        outaouais: 0,
        "abitibi-temiscamingue": 0,
        "cote-nord": 10,
        "nord-du-quebec": 0,
        "iles-de-la-madeleine": 10,
        gaspesie: 10,
        "laurentides-secteur-hautes-laurentides": 0,
        nunavik: 0,
        "terres-cries-de-la-baie-james": 7,
      },
    },
    {
      id: "mountain",
      label: "Des espaces montagneux à perte de vue",
      img: data.mountain.childImageSharp.fluid,
      weighting: {
        "bas-saint-laurent": 10,
        "saguenay-lac-saint-jean": 6,
        "mauricie-et-centre-du-quebec": 7,
        outaouais: 9,
        "abitibi-temiscamingue": 5,
        "cote-nord": 5,
        "nord-du-quebec": 5,
        "iles-de-la-madeleine": 3,
        gaspesie: 9,
        "laurentides-secteur-hautes-laurentides": 10,
        nunavik: 8,
        "terres-cries-de-la-baie-james": 1,
      },
    },
    {
      id: "firstNation",
      label: "L’immersion dans la culture des Premières Nations",
      img: data.firstNation.childImageSharp.fluid,
      weighting: {
        "bas-saint-laurent": 5,
        "saguenay-lac-saint-jean": 2,
        "mauricie-et-centre-du-quebec": 5,
        outaouais: 8,
        "abitibi-temiscamingue": 7,
        "cote-nord": 9,
        "nord-du-quebec": 8,
        "iles-de-la-madeleine": 5,
        gaspesie: 5,
        "laurentides-secteur-hautes-laurentides": 3,
        nunavik: 10,
        "terres-cries-de-la-baie-james": 10,
      },
    },
  ]

  return (
    <QuizQuestionLayout
      title="Que devez-vous absolument retrouver là où vous habitez?"
      index={index + 1}
    >
      <RadioWithIcon
        name="absolutelyFind"
        options={options}
        onChange={handleChange}
      />
    </QuizQuestionLayout>
  )
}

export default Question6
