// vendors
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useFormikContext } from "formik"

import RadioWithIcon from "../../../components/RadioWithIcon/RadioWithIcon"
import QuizQuestionLayout from "../../../components/QuizQuestionLayout"

const query = graphql`
  query {
    fishMeal: file(name: { eq: "quest2-poissons" }) {
      ...RadioWithIconPictureFragment
    }
    gameMeal: file(name: { eq: "quest2-gibier" }) {
      ...RadioWithIconPictureFragment
    }
    localMeal: file(name: { eq: "quest2-terroir" }) {
      ...RadioWithIconPictureFragment
    }
  }
`

const Question2 = ({ index, onChange }) => {
  const data = useStaticQuery(query)
  const { setFieldValue } = useFormikContext()

  const handleChange = (e) => {
    const { value } = e.target

    const option = options.find(({ id }) => id === value)

    setFieldValue(`weighting.${index}`, option.weighting)

    onChange(e)
  }

  const options = [
    {
      id: "fishMeal",
      label: "Poissons et fruits de mer",
      img: data.fishMeal.childImageSharp.fluid,
      weighting: {
        "bas-saint-laurent": 8,
        "saguenay-lac-saint-jean": 6,
        "mauricie-et-centre-du-quebec": 4,
        outaouais: 0,
        "abitibi-temiscamingue": 9,
        "cote-nord": 9,
        "nord-du-quebec": 6,
        "iles-de-la-madeleine": 10,
        gaspesie: 10,
        "laurentides-secteur-hautes-laurentides": 4,
        nunavik: 8,
        "terres-cries-de-la-baie-james": 7,
      },
    },
    {
      id: "gameMeal",
      label: "Gibier",
      img: data.gameMeal.childImageSharp.fluid,
      weighting: {
        "bas-saint-laurent": 10,
        "saguenay-lac-saint-jean": 10,
        "mauricie-et-centre-du-quebec": 6,
        outaouais: 2,
        "abitibi-temiscamingue": 10,
        "cote-nord": 10,
        "nord-du-quebec": 10,
        "iles-de-la-madeleine": 5,
        gaspesie: 9,
        "laurentides-secteur-hautes-laurentides": 10,
        nunavik: 10,
        "terres-cries-de-la-baie-james": 9,
      },
    },
    {
      id: "localMeal",
      label: "Produits du terroir",
      img: data.localMeal.childImageSharp.fluid,
      weighting: {
        "bas-saint-laurent": 9,
        "saguenay-lac-saint-jean": 8,
        "mauricie-et-centre-du-quebec": 2,
        outaouais: 10,
        "abitibi-temiscamingue": 8,
        "cote-nord": 6,
        "nord-du-quebec": 8,
        "iles-de-la-madeleine": 7,
        gaspesie: 8,
        "laurentides-secteur-hautes-laurentides": 6,
        nunavik: 7,
        "terres-cries-de-la-baie-james": 5,
      },
    },
  ]

  return (
    <QuizQuestionLayout
      title="Quel type de « foodie » êtes-vous?"
      index={index + 1}
    >
      <RadioWithIcon
        name="mealType"
        options={options}
        onChange={handleChange}
      />
    </QuizQuestionLayout>
  )
}

export default Question2
