// vendors
import React, { useState } from "react"
import { css } from "@emotion/core"
import StepContainer from "./StepContainer/StepContainer"
import wrapper from "../../utils/wrapper"
import Progress from "../Progress"
import { useFormikContext } from "formik"
import { fontSizes } from "../../styles/variables"

const QuizWizard = ({ children, onChange, onFinish }) => {
  const { setFieldValue } = useFormikContext()

  const [step, setStep] = useState(0)

  const handleChange = (e) => {
    const { name, value } = e.target

    setFieldValue(name, value)

    onChange(e)

    if (step + 1 > React.Children.count(children) - 1) {
      onFinish()

      return
    }

    setStep(step + 1)

    window.scroll(0, 0)
  }

  return (
    <div
      css={css`
        ${wrapper.bolt()}
        margin-bottom: ${64 / fontSizes[0]}rem;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          max-width: 800px;
        `}
      >
        <Progress
          value={step + 1}
          max={React.Children.count(children)}
          css={css`
            flex-grow: 1;
            flex-shrink: 1;
          `}
        />{" "}
        <span
          css={css`
            flex-grow: 0;
            margin-left: 1em;
            font-size: ${26 / 18}rem;
            font-weight: 600;
          `}
        >
          {step + 1}/{React.Children.count(children)}
        </span>
      </div>

      <div>
        {React.Children.toArray(children).map((child, index) => (
          <StepContainer key={index} visible={index === step}>
            {React.cloneElement(child, { onChange: handleChange, index })}
          </StepContainer>
        ))}
      </div>
    </div>
  )
}

QuizWizard.defaultProps = {
  onChange: () => {},
  onFinish: () => {},
}

export default QuizWizard
