// vendors
import React from "react"
import { useFormikContext } from "formik"

import RadioWithIcon from "../../../components/RadioWithIcon/RadioWithIcon"
import QuizQuestionLayout from "../../../components/QuizQuestionLayout"

const options = [
  {
    id: "hiking",
    label: "De la randonnée",
    img: { src: require("./images/vector-hiking.svg") },
    weighting: {
      "bas-saint-laurent": 10,
      "saguenay-lac-saint-jean": 9,
      "mauricie-et-centre-du-quebec": 9,
      outaouais: 9,
      "abitibi-temiscamingue": 9,
      "cote-nord": 8,
      "nord-du-quebec": 10,
      "iles-de-la-madeleine": 8,
      gaspesie: 8,
      "laurentides-secteur-hautes-laurentides": 8,
      nunavik: 10,
      "terres-cries-de-la-baie-james": 8,
    },
  },
  {
    id: "huntingFishing",
    label: "De la chasse ou de la pêche",
    img: { src: require("./images/vector-fishing.svg") },
    weighting: {
      "bas-saint-laurent": 9,
      "saguenay-lac-saint-jean": 6,
      "mauricie-et-centre-du-quebec": 8,
      outaouais: 2,
      "abitibi-temiscamingue": 10,
      "cote-nord": 10,
      "nord-du-quebec": 9,
      "iles-de-la-madeleine": 7,
      gaspesie: 9,
      "laurentides-secteur-hautes-laurentides": 9,
      nunavik: 8,
      "terres-cries-de-la-baie-james": 10,
    },
  },
  {
    id: "kayak",
    label: "Du kayak",
    img: { src: require("./images/vector-canoe.svg") },
    weighting: {
      "bas-saint-laurent": 7,
      "saguenay-lac-saint-jean": 7,
      "mauricie-et-centre-du-quebec": 6,
      outaouais: 8,
      "abitibi-temiscamingue": 7,
      "cote-nord": 9,
      "nord-du-quebec": 7,
      "iles-de-la-madeleine": 10,
      gaspesie: 7,
      "laurentides-secteur-hautes-laurentides": 10,
      nunavik: 6,
      "terres-cries-de-la-baie-james": 7,
    },
  },
  {
    id: "biking",
    label: "Du vélo",
    img: { src: require("./images/vector-biking.svg") },
    weighting: {
      "bas-saint-laurent": 8,
      "saguenay-lac-saint-jean": 10,
      "mauricie-et-centre-du-quebec": 10,
      outaouais: 10,
      "abitibi-temiscamingue": 6,
      "cote-nord": 6,
      "nord-du-quebec": 8,
      "iles-de-la-madeleine": 6,
      gaspesie: 6,
      "laurentides-secteur-hautes-laurentides": 6,
      nunavik: 7,
      "terres-cries-de-la-baie-james": 6,
    },
  },
  {
    id: "offTrail",
    label: "Des escapades hors-piste",
    img: { src: require("./images/vector-offtrail.svg") },
    weighting: {
      "bas-saint-laurent": 6,
      "saguenay-lac-saint-jean": 8,
      "mauricie-et-centre-du-quebec": 7,
      outaouais: 3,
      "abitibi-temiscamingue": 8,
      "cote-nord": 7,
      "nord-du-quebec": 5,
      "iles-de-la-madeleine": 5,
      gaspesie: 10,
      "laurentides-secteur-hautes-laurentides": 7,
      nunavik: 9,
      "terres-cries-de-la-baie-james": 9,
    },
  },
]

const Question1 = ({ index, onChange }) => {
  const { setFieldValue } = useFormikContext()

  const handleChange = (e) => {
    const { value } = e.target

    const option = options.find(({ id }) => id === value)

    setFieldValue(`weighting.${index}`, option.weighting)

    onChange(e)
  }

  return (
    <QuizQuestionLayout
      title="Que feriez-vous de vos temps libres?"
      index={index + 1}
    >
      <RadioWithIcon
        name="freeTimeOccupation"
        options={options}
        onChange={handleChange}
        isVector
      />
    </QuizQuestionLayout>
  )
}

export default Question1
