// vendors
import React from "react"
import { useFormikContext } from "formik"

import RadioWithIcon from "../../../components/RadioWithIcon/RadioWithIcon"
import QuizQuestionLayout from "../../../components/QuizQuestionLayout"

const options = [
  {
    id: "no",
    label: "Non",
    img: { src: require("./images/vector-sad.svg") },
    weighting: {
      "bas-saint-laurent": 0,
      "saguenay-lac-saint-jean": 8,
      "mauricie-et-centre-du-quebec": 0,
      outaouais: 0,
      "abitibi-temiscamingue": 2,
      "cote-nord": 2,
      "nord-du-quebec": 4,
      "iles-de-la-madeleine": 4,
      gaspesie: 0,
      "laurentides-secteur-hautes-laurentides": 3,
      nunavik: 0,
      "terres-cries-de-la-baie-james": 0,
    },
  },
  {
    id: "inPart",
    label: "En partie",
    img: { src: require("./images/vector-neutral.svg") },
    weighting: {
      "bas-saint-laurent": 10,
      "saguenay-lac-saint-jean": 6,
      "mauricie-et-centre-du-quebec": 10,
      outaouais: 10,
      "abitibi-temiscamingue": 8,
      "cote-nord": 6,
      "nord-du-quebec": 8,
      "iles-de-la-madeleine": 9,
      gaspesie: 10,
      "laurentides-secteur-hautes-laurentides": 7,
      nunavik: 3,
      "terres-cries-de-la-baie-james": 8,
    },
  },
  {
    id: "yes",
    label: "Absolument",
    img: { src: require("./images/vector-happy.svg") },
    weighting: {
      "bas-saint-laurent": 5,
      "saguenay-lac-saint-jean": 3,
      "mauricie-et-centre-du-quebec": 2,
      outaouais: 5,
      "abitibi-temiscamingue": 7,
      "cote-nord": 8,
      "nord-du-quebec": 6,
      "iles-de-la-madeleine": 6,
      gaspesie: 5,
      "laurentides-secteur-hautes-laurentides": 5,
      nunavik: 10,
      "terres-cries-de-la-baie-james": 10,
    },
  },
]

const Question10 = ({ index, onChange }) => {
  const { setFieldValue } = useFormikContext()

  const handleChange = (e) => {
    const { value } = e.target

    const option = options.find(({ id }) => id === value)

    setFieldValue(`weighting.${index}`, option.weighting)

    onChange(e)
  }

  return (
    <QuizQuestionLayout
      title="Aimeriez-vous découvrir une nouvelle culture?"
      index={index + 1}
    >
      <RadioWithIcon
        name="openToNewCulture"
        options={options}
        onChange={handleChange}
        isVector
      />
    </QuizQuestionLayout>
  )
}

export default Question10
