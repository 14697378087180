// vendors
import React, { useState } from "react"
import Layout from "../../components/Layout"
import { Formik } from "formik"
import { Router } from "@reach/router"
import { Helmet } from "react-helmet"

// views
import QuizWizard from "../../components/QuizWizard"
import Intro from "../../views/QuizDecouvrezVotreRegion/Intro"
import Question1 from "../../views/QuizDecouvrezVotreRegion/Question1"
import Question2 from "../../views/QuizDecouvrezVotreRegion/Question2"
import Question3 from "../../views/QuizDecouvrezVotreRegion/Question3"
import Question4 from "../../views/QuizDecouvrezVotreRegion/Question4"
import Question5 from "../../views/QuizDecouvrezVotreRegion/Question5"
import Question6 from "../../views/QuizDecouvrezVotreRegion/Question6"
import Question7 from "../../views/QuizDecouvrezVotreRegion/Question7"
import Question8 from "../../views/QuizDecouvrezVotreRegion/Question8"
import Question9 from "../../views/QuizDecouvrezVotreRegion/Question9"
import Question10 from "../../views/QuizDecouvrezVotreRegion/Question10"
import InformationForm, {
  initialValues as initalValuesInformationForm,
  formValidateSchema as formValidateSchemaInformationForm,
} from "../../views/QuizDecouvrezVotreRegion/InformationForm"
import Result from "../../views/QuizDecouvrezVotreRegion/Result"
import serializeData from "../../utils/serializeData"
import Seo from "../../components/Seo/Seo"

const DecouvrezVotreRegionSarrosPage = () => {
  const [stage, setStage] = useState(0)

  const initialValues = {
    ...initalValuesInformationForm,
    freeTimeOccupation: "",
    mealType: "",
    landscape: "",
    convenient: "",
    motivation: "",
    absolutelyFind: "",
    personality: "",
    season: "",
    eveningActivity: "",
    openToNewCulture: "",
    weighting: [],
  }

  const handleChangeStage = () => {
    setStage(stage + 1)

    window.scroll(0, 0)
  }

  const handleSubmit = async (values, actions) => {
    const data = {
      "mauticform[formId]": 5,
      "mauticform[return]": "",
      "mauticform[formName]": "decouvrezvotreregion",
      "mauticform[freetimeoccupation]": values.freeTimeOccupation,
      "mauticform[mealtype1]": values.mealType,
      "mauticform[landscape]": values.landscape,
      "mauticform[convenient]": values.convenient,
      "mauticform[motivation]": values.motivation,
      "mauticform[absolutelyfind]": values.absolutelyFind,
      "mauticform[personality]": values.personality,
      "mauticform[season]": values.season,
      "mauticform[eveningactivity]": values.eveningActivity,
      "mauticform[opentonewculture]": values.openToNewCulture,
      "mauticform[firstname]": values.firstname,
      "mauticform[lastname]": values.lastname,
      "mauticform[email]": values.email,
      "mauticform[status]": values.status,
      "mauticform[university]": values.university,
      "mauticform[level]": values.level,
    }

    await fetch("https://mautic.equipesarros.ca/form/submit?formId=5", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
      },
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      redirect: "manual",
      body: serializeData(data),
    })
      .then((res) => {
        console.log(res.status)

        console.log(res.redirected)

        if (res.redirected) {
          console.log("redirect")
        }
      })
      .catch((error) => {
        console.error(error)
      })

    handleChangeStage()

    actions.setSubmitting(false)
  }

  const Wizard = ({ onFinish, questionId }) => (
    <>
      {stage === 0 && (
        <QuizWizard onFinish={onFinish} step={Number(questionId)}>
          <Question1 />

          <Question2 />

          <Question3 />

          <Question4 />

          <Question5 />

          <Question6 />

          <Question7 />

          <Question8 />

          <Question9 />

          <Question10 />
        </QuizWizard>
      )}

      {stage === 1 && <InformationForm />}

      {stage === 2 && <Result />}
    </>
  )

  return (
    <Layout>
      <Seo
        title="Découvrez votre région SARROS"
        description="Faites le quiz pour découvrir la région qui s’accorde le mieux avec votre personnalité"
      />

      <Helmet>
        <meta
          property="og:image"
          content="https://www.equipesarros.ca/assets/quiz-sarros-large.jpg"
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={formValidateSchemaInformationForm}
      >
        {(formikProps) => (
          <form onSubmit={formikProps.handleSubmit}>
            <Router basepath="/quiz/decouvrez-votre-region-sarros">
              <Intro path="/" default />

              <Wizard onFinish={handleChangeStage} path="questions/*" />
            </Router>
          </form>
        )}
      </Formik>
    </Layout>
  )
}

export default DecouvrezVotreRegionSarrosPage
