// vendors
import React from "react"
import { useFormikContext } from "formik"

import QuizQuestionLayout from "../../../components/QuizQuestionLayout"
import RadioGroupAsList from "../../../components/RadioGroupAsList"

const options = [
  {
    id: "comfortZone",
    label: "Sortir de votre zone de confort en découvrant une nouvelle culture",
    weighting: {
      "bas-saint-laurent": 5,
      "saguenay-lac-saint-jean": 2,
      "mauricie-et-centre-du-quebec": 4,
      outaouais: 7,
      "abitibi-temiscamingue": 8,
      "cote-nord": 5,
      "nord-du-quebec": 6,
      "iles-de-la-madeleine": 5,
      gaspesie: 5,
      "laurentides-secteur-hautes-laurentides": 4,
      nunavik: 10,
      "terres-cries-de-la-baie-james": 10,
    },
  },
  {
    id: "trust",
    label: "Développer des relations de confiance avec vos équipes",
    weighting: {
      "bas-saint-laurent": 10,
      "saguenay-lac-saint-jean": 6,
      "mauricie-et-centre-du-quebec": 7,
      outaouais: 9,
      "abitibi-temiscamingue": 10,
      "cote-nord": 10,
      "nord-du-quebec": 8,
      "iles-de-la-madeleine": 8,
      gaspesie: 10,
      "laurentides-secteur-hautes-laurentides": 10,
      nunavik: 9,
      "terres-cries-de-la-baie-james": 8,
    },
  },
  {
    id: "pillar",
    label: "Devenir un pilier au sein d’une communauté",
    weighting: {
      "bas-saint-laurent": 9,
      "saguenay-lac-saint-jean": 5,
      "mauricie-et-centre-du-quebec": 9,
      outaouais: 8,
      "abitibi-temiscamingue": 9,
      "cote-nord": 7,
      "nord-du-quebec": 10,
      "iles-de-la-madeleine": 10,
      gaspesie: 9,
      "laurentides-secteur-hautes-laurentides": 8,
      nunavik: 8,
      "terres-cries-de-la-baie-james": 7,
    },
  },
  {
    id: "distance",
    label:
      "Obtenir tous les avantages de la distance tout en restant près des grands centres",
    weighting: {
      "bas-saint-laurent": 8,
      "saguenay-lac-saint-jean": 8,
      "mauricie-et-centre-du-quebec": 8,
      outaouais: 10,
      "abitibi-temiscamingue": 7,
      "cote-nord": 6,
      "nord-du-quebec": 2,
      "iles-de-la-madeleine": 0,
      gaspesie: 0,
      "laurentides-secteur-hautes-laurentides": 9,
      nunavik: 0,
      "terres-cries-de-la-baie-james": 2,
    },
  },
]

const Question5 = ({ index, onChange }) => {
  const { setFieldValue } = useFormikContext()

  const handleChange = (e) => {
    const { value } = e.target

    const option = options.find(({ id }) => id === value)

    setFieldValue(`weighting.${index}`, option.weighting)

    onChange(e)
  }

  return (
    <QuizQuestionLayout
      title="Quel but vous motive le plus à travailler en région?"
      index={index + 1}
    >
      <RadioGroupAsList
        name="motivation"
        options={options}
        onChange={handleChange}
      />
    </QuizQuestionLayout>
  )
}

export default Question5
