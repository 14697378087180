// vendors
import React from "react"
import { useFormikContext } from "formik"

import RadioWithIcon from "../../../components/RadioWithIcon/RadioWithIcon"
import QuizQuestionLayout from "../../../components/QuizQuestionLayout"

const options = [
  {
    id: "summer",
    label: "L’été",
    img: { src: require("./images/vector-summer.svg") },
    weighting: {
      "bas-saint-laurent": 10,
      "saguenay-lac-saint-jean": 9,
      "mauricie-et-centre-du-quebec": 10,
      outaouais: 10,
      "abitibi-temiscamingue": 10,
      "cote-nord": 8,
      "nord-du-quebec": 8,
      "iles-de-la-madeleine": 10,
      gaspesie: 10,
      "laurentides-secteur-hautes-laurentides": 8,
      nunavik: 4,
      "terres-cries-de-la-baie-james": 7,
    },
  },
  {
    id: "fallSpring",
    label: "L’automne et le printemps",
    img: { src: require("./images/vector-fall.svg") },
    weighting: {
      "bas-saint-laurent": 9,
      "saguenay-lac-saint-jean": 8,
      "mauricie-et-centre-du-quebec": 9,
      outaouais: 9,
      "abitibi-temiscamingue": 8,
      "cote-nord": 7,
      "nord-du-quebec": 6,
      "iles-de-la-madeleine": 6,
      gaspesie: 8,
      "laurentides-secteur-hautes-laurentides": 10,
      nunavik: 0,
      "terres-cries-de-la-baie-james": 6,
    },
  },
  {
    id: "winter",
    label: "L’hiver",
    img: { src: require("./images/vector-winter.svg") },
    weighting: {
      "bas-saint-laurent": 8,
      "saguenay-lac-saint-jean": 10,
      "mauricie-et-centre-du-quebec": 8,
      outaouais: 8,
      "abitibi-temiscamingue": 7,
      "cote-nord": 9,
      "nord-du-quebec": 10,
      "iles-de-la-madeleine": 7,
      gaspesie: 9,
      "laurentides-secteur-hautes-laurentides": 9,
      nunavik: 10,
      "terres-cries-de-la-baie-james": 9,
    },
  },
]

const Question8 = ({ index, onChange }) => {
  const { setFieldValue } = useFormikContext()

  const handleChange = (e) => {
    const { value } = e.target

    const option = options.find(({ id }) => id === value)

    setFieldValue(`weighting.${index}`, option.weighting)

    onChange(e)
  }

  return (
    <QuizQuestionLayout
      title="Quelle saison vous procure la température idéale?"
      index={index + 1}
    >
      <RadioWithIcon
        name="season"
        options={options}
        onChange={handleChange}
        isVector
      />
    </QuizQuestionLayout>
  )
}

export default Question8
