// vendors
import React from "react"
import styled from "@emotion/styled"
import { colors, fontSizes } from "../../styles/variables"
import { css } from "@emotion/core"

const Number = styled.div`
  display: inline-block;
  font-size: ${21 / fontSizes[0]}rem;
  height: ${36 / fontSizes[0]}rem;
  width: ${36 / fontSizes[0]}rem;
  line-height: ${36 / 21}em;
  background-color: ${colors.accent};
  text-align: center;
  border-radius: 50%;
  flex-shrink: 0;

  @media (min-width: ${fontSizes.breakpoint}px) {
    font-size: ${64 / fontSizes[1]}rem;
    height: ${110 / fontSizes[1]}rem;
    width: ${110 / fontSizes[1]}rem;
    line-height: ${110 / 64}em;
  }
`

const QuizQuestionLayout = ({ title, index, children }) => {
  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: ${36 / fontSizes[0]}rem auto;
        grid-template-rows: auto 1fr;

        grid-gap: ${25 / fontSizes[1]}rem;

        @media (min-width: ${fontSizes.breakpoint}px) {
          grid-template-columns: ${110 / fontSizes[1]}rem auto;
        }
      `}
    >
      <div
        css={css`
          display: grid;

          grid-template-columns: ${36 / fontSizes[0]}rem auto;
          grid-template-columns: subgrid;

          grid-gap: ${25 / fontSizes[1]}rem;

          grid-row: 1 / span 1;
          grid-column: 1 / span 2;

          align-items: center;

          font-size: ${24 / fontSizes[0]}rem;
          font-weight: 800;
          text-rendering: optimizeLegibility;
          line-height: ${48 / fontSizes[6]};
          margin-top: 2em;
          word-break: break-all;
          word-break: break-word; /* safari fix */
          hyphens: auto;

          @media (min-width: ${fontSizes.breakpoint}px) {
            grid-template-columns: ${110 / fontSizes[1]}rem auto;
            font-size: ${fontSizes[6] / fontSizes[1]}em;
          }
        `}
      >
        <Number>{index}</Number>

        <p
          css={css`
            margin: 0;
          `}
        >
          {title}
        </p>
      </div>

      <div
        css={css`
          grid-row: 2 / span 1;
          grid-column: 1 / span 2;

          @media (min-width: ${fontSizes.breakpoint}px) {
            grid-column: 2 / span 1;
          }
        `}
      >
        {children}
      </div>
    </div>
  )
}

export default QuizQuestionLayout
