// vendors
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

// components
import SidebarLayout from "../../../components/SidebarLayout/SidebarLayout"
import Button from "../../../components/Button/Button"

const query = graphql`
  query {
    img: file(name: { eq: "img-accueil-quiz-region" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const DecouvrezVotreRegionSarrosPage = ({ onStart }) => {
  const data = useStaticQuery(query)

  const sidebar = (
    <div>
      <Img fluid={data.img.childImageSharp.fluid} />
    </div>
  )

  return (
    <SidebarLayout sideWidth="40%" gap="40px" sidebar={sidebar}>
      <h1>Découvrez votre région SARROS</h1>

      <p>
        Votre type de personnalité doit s’accorder avec la réalité du milieu
        adopté pour une pratique médicale et une vie personnelle épanouie.
      </p>

      <p>
        Le quiz suivant pourra vous aider à vous positionner parmi les 12
        régions désignées, éloignées ou isolées.
      </p>

      <Button secondary tag="link" to="questions" onClick={onStart}>
        Commencez le quiz
      </Button>
    </SidebarLayout>
  )
}

export default DecouvrezVotreRegionSarrosPage
