// vendors
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useFormikContext } from "formik"

import RadioWithIcon from "../../../components/RadioWithIcon/RadioWithIcon"
import QuizQuestionLayout from "../../../components/QuizQuestionLayout"

const query = graphql`
  query {
    mountain: file(name: { eq: "quest3-montage" }) {
      ...RadioWithIconPictureFragment
    }
    water: file(name: { eq: "quest3-eau" }) {
      ...RadioWithIconPictureFragment
    }
    toundra: file(name: { eq: "quest3-toundra" }) {
      ...RadioWithIconPictureFragment
    }
    plain: file(name: { eq: "quest3-plaine" }) {
      ...RadioWithIconPictureFragment
    }
  }
`

const Question3 = ({ index, onChange }) => {
  const data = useStaticQuery(query)
  const { setFieldValue } = useFormikContext()

  const options = [
    {
      id: "mountain",
      label: "La montagne",
      img: data.mountain.childImageSharp.fluid,
      weighting: {
        "bas-saint-laurent": 10,
        "saguenay-lac-saint-jean": 10,
        "mauricie-et-centre-du-quebec": 8,
        outaouais: 10,
        "abitibi-temiscamingue": 8,
        "cote-nord": 5,
        "nord-du-quebec": 5,
        "iles-de-la-madeleine": 4,
        gaspesie: 9,
        "laurentides-secteur-hautes-laurentides": 8,
        nunavik: 7,
        "terres-cries-de-la-baie-james": 3,
      },
    },
    {
      id: "water",
      label: "L’eau(mer, lac et rivière)",
      img: data.water.childImageSharp.fluid,
      weighting: {
        "bas-saint-laurent": 9,
        "saguenay-lac-saint-jean": 8,
        "mauricie-et-centre-du-quebec": 3,
        outaouais: 0,
        "abitibi-temiscamingue": 10,
        "cote-nord": 10,
        "nord-du-quebec": 0,
        "iles-de-la-madeleine": 10,
        gaspesie: 10,
        "laurentides-secteur-hautes-laurentides": 10,
        nunavik: 0,
        "terres-cries-de-la-baie-james": 7,
      },
    },
    {
      id: "toundra",
      label: "La toundra",
      img: data.toundra.childImageSharp.fluid,
      weighting: {
        "bas-saint-laurent": 0,
        "saguenay-lac-saint-jean": 3,
        "mauricie-et-centre-du-quebec": 0,
        outaouais: 0,
        "abitibi-temiscamingue": 0,
        "cote-nord": 0,
        "nord-du-quebec": 4,
        "iles-de-la-madeleine": 0,
        gaspesie: 1,
        "laurentides-secteur-hautes-laurentides": 0,
        nunavik: 10,
        "terres-cries-de-la-baie-james": 8,
      },
    },
    {
      id: "plain",
      label: "La plaine",
      img: data.plain.childImageSharp.fluid,
      weighting: {
        "bas-saint-laurent": 4,
        "saguenay-lac-saint-jean": 3,
        "mauricie-et-centre-du-quebec": 1,
        outaouais: 6,
        "abitibi-temiscamingue": 3,
        "cote-nord": 1,
        "nord-du-quebec": 2,
        "iles-de-la-madeleine": 5,
        gaspesie: 0,
        "laurentides-secteur-hautes-laurentides": 0,
        nunavik: 2,
        "terres-cries-de-la-baie-james": 5,
      },
    },
  ]

  const handleChange = (e) => {
    const { value } = e.target

    const option = options.find(({ id }) => id === value)

    setFieldValue(`weighting.${index}`, option.weighting)

    onChange(e)
  }

  return (
    <QuizQuestionLayout
      title="Quel paysage rêvez-vous de voir tous les jours?"
      index={index + 1}
    >
      <RadioWithIcon
        name="landscape"
        options={options}
        onChange={handleChange}
      />
    </QuizQuestionLayout>
  )
}

export default Question3
