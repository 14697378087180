// vendors
import React from "react"
import { css } from "@emotion/core"
import { useStaticQuery, graphql } from "gatsby"
import { useFormikContext } from "formik"
import Image from "gatsby-image"

import SidebarLayout from "../../../components/SidebarLayout/SidebarLayout"
import { colors, fonts, fontSizes } from "../../../styles/variables"
import Button from "../../../components/Button/Button"
import wrapper from "../../../utils/wrapper"
import iconHeart from "../../../images/icon-heart.svg"

import descriptionByRegion from "./descriptionByRegion.json"

const titleStyle = css`
  font-size: ${fontSizes[4] / fontSizes[0]}em;
  font-weight: 600;
  text-rendering: optimizeLegibility;
  line-height: ${48 / fontSizes[6]};
  word-break: break-all;
  word-break: break-word; /* safari fix */
  hyphens: auto;

  @media (min-width: ${fontSizes.breakpoint}px) {
    font-size: ${fontSizes[6] / fontSizes[1]}em;
  }
`

const query = graphql`
  query {
    regions: allSanityRegion {
      nodes {
        title
        meta {
          slug {
            current
          }
        }
        thumbnail {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`

const ResultPage = () => {
  const data = useStaticQuery(query)
  const { values } = useFormikContext()

  const results = values.weighting.reduce((acc, cur) => {
    Object.keys(cur).forEach((key) => {
      if (!acc[key]) acc[key] = 0

      acc[key] += cur[key]
    })

    return acc
  }, {})

  const reducedResult = Object.keys(results)
    .map((key) => ({ key, score: results[key] }))
    .sort((a, b) => b.score - a.score)
    .slice(0, 3)
    .map((obj) => {
      const { key } = obj

      const { title, thumbnail, meta } = data.regions.nodes.find(
        ({
          meta: {
            slug: { current },
          },
        }) => current === key
      )

      return {
        thumbnail: thumbnail.asset.fluid,
        title,
        description: descriptionByRegion[key],
        url: `/regions/${meta.slug.current}`,
      }
    })

  return (
    <div>
      <SidebarLayout
        sidebar={<Image fluid={reducedResult[0].thumbnail} />}
        gap="40px"
        sideWidth="40%"
        breakpoint="768"
        sideAfter
      >
        <h2
          className="h2"
          css={css`
            margin-top: 0;
            ${titleStyle};
          `}
        >
          Votre région SARROS est :
          <span
            css={css`
              color: ${colors.blueyGreen};
              display: block;
            `}
          >
            {reducedResult[0].title}
          </span>
        </h2>

        <p>{reducedResult[0].description}</p>

        <Button tag="link" secondary to={reducedResult[0].url}>
          Voir la région
        </Button>
      </SidebarLayout>

      <section
        css={{
          backgroundColor: colors.veryLightGreen,
          padding: `${fontSizes[9] / fontSizes[0]}em 0`,
          marginTop: `${fontSizes[9] / fontSizes[0]}em`,
        }}
      >
        <div
          css={css`
            ${wrapper.bolt()};
          `}
        >
          <h2
            css={css`
              margin-top: 0;
              ${titleStyle}
            `}
          >
            <img src={iconHeart} alt="" css={{ height: "1em" }} /> Vous pourriez
            aussi aimer:
          </h2>

          <div
            css={css`
              display: flex;
              flex-wrap: wrap;
              justify-content: center;

              margin: -30px;
            `}
          >
            {reducedResult.slice(1, 3).map((region) => (
              <div
                css={css`
                  flex: 1 1;

                  display: flex;
                  flex-wrap: wrap;
                  justify-content: center;

                  margin: 30px 15px;

                  min-width: 260px;

                  > * {
                    margin: 0 15px;
                  }
                `}
              >
                <Image
                  fluid={region.thumbnail}
                  css={css`
                    flex-grow: 1;
                    flex-shrink: 1;
                    border-radius: 100%;
                    min-width: 260px;
                    align-self: center;
                    justify-content: center;
                  `}
                />

                <div
                  css={css`
                    flex-grow: 1;
                    flex-shrink: 1;
                    flex-basis: 0;
                    min-width: 260px;
                  `}
                >
                  <h3
                    css={css`
                      font-family: ${fonts.body};
                      font-size: ${fontSizes[3] / fontSizes[0]}em;
                      font-weight: 600;
                      text-rendering: optimizeLegibility;
                      line-height: ${48 / fontSizes[6]};
                      word-break: break-all;
                      word-break: break-word; /* safari fix */
                      hyphens: auto;

                      @media (min-width: ${fontSizes.breakpoint}px) {
                        font-size: ${fontSizes[5] / fontSizes[1]}em;
                      }
                    `}
                  >
                    {region.title}
                  </h3>

                  <Button tag="link" to={region.url} secondary>
                    Voir la région
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  )
}

export default ResultPage
