// vendors
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useFormikContext } from "formik"

import RadioWithIcon from "../../../components/RadioWithIcon/RadioWithIcon"
import QuizQuestionLayout from "../../../components/QuizQuestionLayout"

const query = graphql`
  query {
    dinner: file(name: { eq: "quest9-bonne-table" }) {
      ...RadioWithIconPictureFragment
    }
    festival: file(name: { eq: "quest9-festival-musique" }) {
      ...RadioWithIconPictureFragment
    }
    camping: file(name: { eq: "quest9-camper" }) {
      ...RadioWithIconPictureFragment
    }
  }
`

const Question9 = ({ index, onChange }) => {
  const data = useStaticQuery(query)
  const { setFieldValue } = useFormikContext()

  const handleChange = (e) => {
    const { value } = e.target

    const option = options.find(({ id }) => id === value)

    setFieldValue(`weighting.${index}`, option.weighting)

    onChange(e)
  }

  const options = [
    {
      id: "dinner",
      label: "Découvrir une bonne table",
      img: data.dinner.childImageSharp.fluid,
      weighting: {
        "bas-saint-laurent": 9,
        "saguenay-lac-saint-jean": 8,
        "mauricie-et-centre-du-quebec": 7,
        outaouais: 10,
        "abitibi-temiscamingue": 8,
        "cote-nord": 8,
        "nord-du-quebec": 6,
        "iles-de-la-madeleine": 9,
        gaspesie: 9,
        "laurentides-secteur-hautes-laurentides": 4,
        nunavik: 0,
        "terres-cries-de-la-baie-james": 4,
      },
    },
    {
      id: "festival",
      label: "Assister à un festival de musique",
      img: data.festival.childImageSharp.fluid,
      weighting: {
        "bas-saint-laurent": 8,
        "saguenay-lac-saint-jean": 7,
        "mauricie-et-centre-du-quebec": 0,
        outaouais: 8,
        "abitibi-temiscamingue": 10,
        "cote-nord": 4,
        "nord-du-quebec": 5,
        "iles-de-la-madeleine": 7,
        gaspesie: 10,
        "laurentides-secteur-hautes-laurentides": 5,
        nunavik: 1,
        "terres-cries-de-la-baie-james": 2,
      },
    },
    {
      id: "camping",
      label: "Camper en plein air",
      img: data.camping.childImageSharp.fluid,
      weighting: {
        "bas-saint-laurent": 10,
        "saguenay-lac-saint-jean": 6,
        "mauricie-et-centre-du-quebec": 8,
        outaouais: 7,
        "abitibi-temiscamingue": 7,
        "cote-nord": 9,
        "nord-du-quebec": 10,
        "iles-de-la-madeleine": 6,
        gaspesie: 8,
        "laurentides-secteur-hautes-laurentides": 10,
        nunavik: 10,
        "terres-cries-de-la-baie-james": 6,
      },
    },
  ]

  return (
    <QuizQuestionLayout
      title="Quelle type de soirée aimeriez-vous passer?"
      index={index + 1}
    >
      <RadioWithIcon
        name="eveningActivity"
        options={options}
        onChange={handleChange}
      />
    </QuizQuestionLayout>
  )
}

export default Question9
