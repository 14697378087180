// vendors
import React from "react"
import { css } from "@emotion/core"
import { useFormikContext } from "formik"
import * as Yup from "yup"

import Input from "../../../components/Input"
import SegementedControls from "../../../components/SegementedControls/SegementedControls"
import { ErrorMessage, FormGroup, Label } from "../../Form/style"
import Button from "../../../components/Button/Button"
import wrapper from "../../../utils/wrapper"

import levelOptions from "../../../data/levels"
import universities from "../../../data/universities"
import { greaterThen } from "../../../utils/media-query"

export const formValidateSchema = Yup.object().shape({
  firstname: Yup.string().required("Information incomplète"),
  lastname: Yup.string().required("Information incomplète"),
  status: Yup.string().required("Information incomplète"),
  university: Yup.string().required("Information incomplète"),
  level: Yup.string().required("Information incomplète"),
  email: Yup.string()
    .email("Adresse courriel invalide")
    .required("Information incomplète"),
})

export const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  status: "",
  university: "",
  level: "",
}

const InformationForm = () => {
  const { values, touched, errors, handleChange, handleBlur, isSubmitting } =
    useFormikContext()

  return (
    <div
      css={css`
        ${wrapper.bolt()}
        margin-bottom: ${120 / 18}rem;

        display: grid;
        grid-gap: 2em;
        grid-template-columns: repeat(1, 1fr);

        ${greaterThen(640)} {
          grid-template-columns: repeat(2, 1fr);
        }
      `}
    >
      <p
        className="h1"
        css={css`
          grid-column: 1 / -1;
        `}
      >
        Prêt(e) à découvrir votre résultat?
      </p>

      <FormGroup
        css={css`
          grid-column: span 1;
        `}
      >
        <Label htmlFor="input-firstname">Prénom</Label>

        <Input
          type="text"
          id="input-firstname"
          name="firstname"
          withBackgroundColor
          value={values.firstname}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.firstname && touched.firstname}
        />

        <ErrorMessage>
          {errors.firstname && touched.firstname && <p>{errors.firstname}</p>}
        </ErrorMessage>
      </FormGroup>

      <FormGroup
        css={css`
          grid-column: span 1;
        `}
      >
        <Label htmlFor="input-lastname">Nom</Label>

        <Input
          type="text"
          id="input-lastname"
          name="lastname"
          withBackgroundColor
          value={values.lastname}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.lastname && touched.lastname}
        />

        <ErrorMessage>
          {errors.lastname && touched.lastname && <p>{errors.lastname}</p>}
        </ErrorMessage>
      </FormGroup>

      <FormGroup
        css={css`
          grid-column: 1 / -1;
        `}
      >
        <Label htmlFor="input-email">Courriel</Label>

        <Input
          type="email"
          id="input-email"
          name="email"
          withBackgroundColor
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.email && touched.email}
        />

        <ErrorMessage>
          {errors.email && touched.email && <p>{errors.email}</p>}
        </ErrorMessage>
      </FormGroup>

      <FormGroup
        css={css`
          grid-column: 1 / -1;
        `}
      >
        <Label htmlFor="input-status">Statut</Label>

        <SegementedControls
          type="text"
          id="input-status"
          name="status"
          withBackgroundColor
          breakpoint="768"
          value={values.status}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.status && touched.status}
          options={[
            { label: "Étudiant", value: "etudiant" },
            { label: "Externe", value: "externe" },
            { label: "Résident", value: "resident" },
            { label: "Médecin pratiquant", value: "pratiquant" },
            { label: "Autre", value: "autre" },
          ]}
        />

        <ErrorMessage>
          {errors.status && touched.status && <p>{errors.status}</p>}
        </ErrorMessage>
      </FormGroup>

      <FormGroup
        css={css`
          grid-column: 1 / -1;
        `}
      >
        <Label htmlFor="input-university">Université</Label>

        <SegementedControls
          type="text"
          id="input-university"
          name="university"
          breakpoint="768"
          withBackgroundColor
          value={values.university}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.university && touched.university}
          wrap
          options={universities}
          css={css`
            width: 100%;
          `}
        />

        <ErrorMessage>
          {errors.university && touched.university && (
            <p>{errors.university}</p>
          )}
        </ErrorMessage>
      </FormGroup>

      <FormGroup
        css={css`
          grid-column: 1 / -1;
        `}
      >
        <Label htmlFor="input-level">Niveau d'étude</Label>

        <SegementedControls
          type="text"
          id="input-level"
          name="level"
          breakpoint="768"
          withBackgroundColor
          value={values.level}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.level && touched.level}
          options={levelOptions}
        />

        <ErrorMessage>
          {errors.level && touched.level && <p>{errors.level}</p>}
        </ErrorMessage>
      </FormGroup>

      <div
        css={css`
          grid-column: 1 / -1;
        `}
      >
        <Button type="submit" secondary disabled={isSubmitting}>
          Votre résultat
        </Button>
      </div>
    </div>
  )
}

export default InformationForm
