// vendors
import React from "react"
import { css } from "@emotion/core"
import { colors } from "../../styles/variables"

const Progress = ({ max, value, ...rest }) => {
  return (
    <progress
      max={max}
      value={value}
      css={css`
        border: none;
        appearance: none;
        height: ${48 / 18}em;
        border-radius: 24px;
        background-color: ${colors.veryLightGreen};

        ::-webkit-progress-bar {
          border-radius: 24px;
          background-color: ${colors.veryLightGreen};
        }

        ::-webkit-progress-value {
          border-radius: 24px;
          background-color: ${colors.blueyGreen};
          min-width: ${48 / 18}em;
        }

        ::-moz-progress-bar {
          border-radius: 24px;
          min-width: ${48 / 18}em;
          background-color: ${colors.blueyGreen};
        }
      `}
      {...rest}
    >
      {(100 * value) / max}%
    </progress>
  )
}

Progress.defaultProps = {
  max: 100,
}

export default Progress
