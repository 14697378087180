// vendors
import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { colors, transition } from "../../styles/variables"
import VectorPinPoint from "../../images/VectorPinPoint"

const PinPoint = styled(VectorPinPoint)`
  height: ${38 / 18}rem;
  margin-right: ${28 / 58}em;
  flex-shrink: 0;

  g {
    transition: all ${transition.speed.fast} ${transition.curve.default};
    fill: ${colors.accent};
  }
`

const Title = styled.span`
  :after {
    display: block;
    height: 4px;
    content: "";
    background-color: currentColor;
    transform: scaleX(0);
    transform-origin: left;
    transition: all ${transition.speed.fast} ${transition.curve.default};
  }
`

const Wrapper = styled.label`
  display: inline-flex;
  align-items: center;
  font-size: ${28 / 18}em;
  line-height: ${33 / 28}em;
  cursor: pointer;
  transition: all ${transition.speed.fast} ${transition.curve.default};
  margin-bottom: ${42 / 28}em;

  :hover,
  :focus {
    color: ${colors.primary};

    ${Title} {
      :after {
        transform: scaleX(1);
        background-color: ${colors.primary};
      }
    }

    ${PinPoint} {
      g {
        fill: ${colors.primary};
      }
    }
  }
`

const RadioGroupAsList = ({ name, options, onChange }) => {
  return (
    <div
      css={css`
        display: flex;
        flex-flow: column;
        align-items: flex-start;
      `}
    >
      {options.map((option) => {
        const labetId = `radio-${name}-${option.id}`

        return (
          <Wrapper htmlFor={labetId}>
            <input
              type="radio"
              name={name}
              value={option.id}
              id={labetId}
              onChange={onChange}
              css={css`
                display: none;
              `}
            />
            <PinPoint color={colors.accent} />

            <Title>{option.label}</Title>
          </Wrapper>
        )
      })}
    </div>
  )
}

export default RadioGroupAsList
